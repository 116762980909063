import ENUM from './enum';
import service from '@/_services/service';
import {DateFormater} from '@/_helpers/funciones';

export const grupomodulo = {
  	namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
		id:0,
		myData: [],
		myDataHijo: [],
		messageError:'',
		collapseModal:false,
		registerModal:false,
		updatedModal:false,
	},
	getters: {
		myDatatable: state => {
			let chart = [];
			if(state.myData.length !== 0){
				state.myData.sort(function(a,b){
					if (a.FgActGpoModule < b.FgActGpoModule) return 1;
					else if (a.FgActGpoModule > b.FgActGpoModule) return -1;
					else {
                        return a.Order - b.Order;
                    }
					
				})
				state.myData.map(function(e) {
					chart.push({
						id:e.GpoModuleId,
						padre:e.GpoModuleParentId,
						moduloEs:e.GpoModuleNameEs,
						moduloEn:e.GpoModuleNameEn,
						description: e.GpoModuleDs,
						orden: parseInt(e.Order),
						nivel:parseInt(e.Level),
						hijos: (e.GpoModuleChildJson === undefined) ? [] : e.GpoModuleChildJson,
						ultimo_usuario: e.TransaLogin,
						textStatus: e.Status,
						status: e.FgActGpoModule,
						fecharegistrotransa: e.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(e.TransaRegDate) : 'N/A',
						_classes: e.Status === "ACTIVO" ? '' : 'table-danger'
					});
					
					if(e.GpoModuleChildJson.length !== 0){
						const x = e.GpoModuleChildJson.sort(function(a,b){
							if (a.FgActGpoModule < b.FgActGpoModule) return 1;
							else if (a.FgActGpoModule > b.FgActGpoModule) return -1;
							else {
								return a.Order - b.Order;
							}
						});
						x.map(function(e) {
							chart.push({
								id:e.GpoModuleId,
								padre:e.GpoModuleParentId,
								moduloEs: e.GpoModuleNameEs,
								moduloEn: e.GpoModuleNameEn,
								description: e.GpoModuleDs,
								orden: parseInt(e.Order),
								nivel:parseInt(e.Level),
								hijos: (e.GpoModuleChildJson === undefined) ? [] : e.GpoModuleChildJson,
								ultimo_usuario: e.TransaLogin,
								textStatus: e.Status,
								status: e.FgActGpoModule,
								fecharegistrotransa: e.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(e.TransaRegDate) : 'N/A',
								_classes: e.Status === "ACTIVO" ? '' : 'table-danger'
							});
						});
					}
				})
				return chart;
			}else{
				return chart;
			}
			
		},
		myDataHijotable: state => {
			let chart = [];
			if(state.myDataHijo.length !== 0){
				state.myDataHijo.sort(function(a,b){
					if (a.FgActGpoModule < b.FgActGpoModule) return 1;
					else if (a.FgActGpoModule > b.FgActGpoModule) return -1;
					else {
                        return a.Order - b.Order;
                    }
					
				})
				state.myDataHijo.map(function(e) {
					chart.push({
						id:e.GpoModuleId,
						padre:e.GpoModuleParentId,
						moduloEs: e.GpoModuleNameEs,
						moduloEn: e.GpoModuleNameEn,
						nombrePadreEs: e.GpoModuleParentNameEs,
						nombrePadreEn: e.GpoModuleParentNameEn,
						description: e.GpoModuleDs,
						orden: parseInt(e.Order),
						nivel:parseInt(e.Level),
						hijos:e.GpoModuleChildJson,
						ultimo_usuario: e.TransaLogin,
						textStatus: e.Status,
						status: e.FgActGpoModule,
						fecharegistrotransa: e.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(e.TransaRegDate) : 'N/A',
						_classes: e.Status === "ACTIVO" ? '' : 'table-danger'
					});
					console.log(e.FgActGpoModule);
				})
				return chart;
			}else{
				return chart;
			}
			
		},
	},
	actions: {
		getGrupoModulolist({commit},{TpModuleId,TpModuleNavegationId,GpoModuleOrigId}){
			commit('getGrupoModulolistRequest');
			
			service.ejecutar('GET', 'GpoModule-list', {TpModuleId:TpModuleId, TpModuleNavegationId:TpModuleNavegationId,GpoModuleOrigId: GpoModuleOrigId, })
			.then(
				GrupoModulo => commit('getGrupoModulolistSuccess', { GrupoModulo }),
				error => {
					commit('messageMutation', error)
					commit('getGrupoModulolistFailure', error)
					//dispatch('alert/error', error, { root: true });
				}
			);
		},
		getGrupoHijoModulolist({commit},{ TpModuleId,TpModuleNavegationId,GpoModuleOrigId }){
			commit('getGrupoModulolistRequest');
			service.ejecutar('GET', 'GpoModule-list',{ TpModuleId,TpModuleNavegationId,GpoModuleOrigId: GpoModuleOrigId }, '' )
			.then(
				GrupoModuloHijo => commit('getGrupoHijoModulolistSuccess', { GrupoModuloHijo }),
				error => {
					commit('messageMutation', error)
					commit('getGrupoModulolistFailure', error)
					//dispatch('alert/error', error, { root: true });
				}
			);
		},
		postGrupoModuloOrden({commit},{Index,GpoModuleId,GpoModuleParentId,Level,OrdenModificada,OrdenOriginal}){
			commit('postGrupoModuloOrdenRequest');
			const valores = [
				{ 
					"GpoModuleId":GpoModuleId, 
					"GpoModuleParentId":GpoModuleParentId, 
					"Level":Level, 
					"Order":OrdenModificada
				}
			]			
			service.ejecutar('PUT', 'GpoModule-Order', valores, { root: 'GpoModuleJson' })
			.then(
				response => {
					try {
						if(response.status === 200){
							const information = response.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('postGrupoModuloOrdenFailure', response.statusText)
							}else{
								commit('messageMutation', information[0].Response)
								commit('postGrupoModuloOrdenSuccess', { response,Index,Level,OrdenModificada,OrdenOriginal })
							}
						}else{
							commit('messageMutation', response.statusText)
							commit('postGrupoModuloOrdenFailure', response.statusText)
						}
					} catch (error) {
						commit('messageMutation', error)
						commit('postregisterGrupoModuloFailure', error);
					}
				},
				error => {
					commit('messageMutation', error)
					commit('postGrupoModuloOrdenFailure', error)
					//dispatch('alert/error', error, { root: true });
				}
			);
		},
		postregisterGrupoModulo ({ commit,dispatch }, { TpModuleId,TpModuleNavegationId,TpVesselId,Icon, OptionSrc, GpoModuleNameEs,GpoModuleNameEn,description,nivel,padre,orden }) {
			commit('postregisterGrupoModuloRequest');
			try {
				//En caso de menu siget web
				const valores = TpModuleNavegationId ? { 
					"GpoModuleParentId":(padre === 0) ? "" : padre, 
					"TpModuleId":TpModuleId,
					"TpModuleNavegationId":TpModuleNavegationId,
					"TpVesselId":TpVesselId,
					"Icon":Icon,
					"OptionSrc":OptionSrc,
					"GpoModuleNameEs":GpoModuleNameEs, 
					"GpoModuleNameEn":GpoModuleNameEn, 
					"GpoModuleDs":description,
					"Level":nivel,
					"Order":orden, 
				}:{ 
					//En caso de siget bascula
					"GpoModuleParentId":(padre === 0) ? "" : padre, 
					"TpModuleId":TpModuleId,
					"TpModuleNavegationId":TpModuleNavegationId,
					"GpoModuleNameEs":GpoModuleNameEs, 
					"GpoModuleNameEn":GpoModuleNameEn, 
					"GpoModuleDs":description,
					"Level":nivel,
					"Order":orden, 
				};

				service.ejecutar('POST', 'GpoModule-insert', valores, { root: 'GpoModuleJson' })
				.then(
					dataGrupoModulo => {
						if(dataGrupoModulo.data.status === 200){
							const information = dataGrupoModulo.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('postregisterGrupoModuloFailure', information[0].Response)
							}else{
								commit('postregisterGrupoModuloSuccess', { information });

								//dispatch('grupomodulo/getGrupoModulolist', '', { root: true });
							}
						}else{
							commit('postregisterGrupoModuloFailure', dataGrupoModulo.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('postregisterGrupoModuloFailure', error)
						//dispatch('alert/error', error, { root: true });
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('postregisterGrupoModuloFailure', error);
			}
		},
		putupdatedGrupoModulo({ commit,dispatch }, { id,TpModuleId,TpModuleNavegationId,TpVesselId,Icon, OptionSrc, GpoModuleNameEs,GpoModuleNameEn,description,nivel,padre,orden,status,hijo  }){
			commit('postregisterGrupoModuloRequest');

			try {
				if(parseInt(nivel) === 1){
					padre = "";
				}
				
				if(status === false && parseInt(nivel) === 1){
					if(hijo.length > 0){
						hijo.map(function(e){
							e.Status = 0;
							e.Order = 0;
						});
					}
					hijo.map(function(e){
						e.UserId = JSON.parse(localStorage.getItem('user')).UserId;
					});
				}
				var valores = TpModuleNavegationId  ? {
					GpoModuleId : id,
					GpoModuleParentId: (padre === 0) ? "" : padre,
					TpModuleId:TpModuleId,
					TpModuleNavegationId:TpModuleNavegationId,
					TpVesselId:TpVesselId,
					Icon:Icon,
					OptionSrc:OptionSrc,
					GpoModuleNameEs:GpoModuleNameEs,
					GpoModuleNameEn:GpoModuleNameEn,
					GpoModuleDs:description,
					Order:orden,
					IdGpoModuleChild: hijo,
					//GpoModuleChildJson:hijo,
					Level:nivel,
					Status: status ? 1 : 0,
					//UserId: JSON.parse(localStorage.getItem('user')).UserId
				}:{
					GpoModuleId : id,
					GpoModuleParentId: (padre === 0) ? "" : padre,
					TpModuleId:TpModuleId,
					TpModuleNavegationId:TpModuleNavegationId,
					GpoModuleNameEs:GpoModuleNameEs,
					GpoModuleNameEn:GpoModuleNameEn,
					GpoModuleDs:description,
					Order:orden,
					IdGpoModuleChild: hijo,
					//GpoModuleChildJson:hijo,
					Level:nivel,
					Status: status ? 1 : 0,
					//UserId: JSON.parse(localStorage.getItem('user')).UserId
				};
			
				service.ejecutar('PUT', 'GpoModule-update', valores, { root: 'GpoModuleJson' })
				.then(
					dataGrupoModulo => {
						if(dataGrupoModulo.data.status === 200){
							const information = dataGrupoModulo.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('postregisterGrupoModuloFailure', information[0].Response)
							}else{
								commit('postregisterGrupoModuloSuccess', { information });

								//dispatch('grupomodulo/getGrupoModulolist', '', { root: true });
							}
						}else{
							commit('postregisterGrupoModuloFailure', dataGrupoModulo.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('postregisterGrupoModuloFailure', error)
						//dispatch('alert/error', error, { root: true });
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('postregisterGrupoModuloFailure', error);
			}
		}
	},
	mutations: {
		//Asignar un id para el updated del grupo modulo
		asignarid (state, { id }) {
			state.id = id;
		},

		messageMutation (state, value ) {
            state.messageError = value;
		},

		//Listar los grupos modulos
		postGrupoModuloOrdenRequest(state) {
			state.apiStateForm = ENUM.LOADING; 
        },
        postGrupoModuloOrdenSuccess(state, { response,Index,Level,OrdenModificada,OrdenOriginal }) {
            try{
				if(response.status === 200){
					//state.myData = response.data.data[0].Json.GpoModuloJson;
					const data = response.data.data[0];
					if(parseInt(Level) === 1){
						let opcion = 0;
						while(opcion < 3){
							state.myData.map(function(e){
								if(opcion === 1 && OrdenModificada === e.Order){
									e.Order = OrdenOriginal;
								}
								if(opcion === 2 && e.GpoModuleId === data.GpoModuleId){
									e.Order = data.Order;
									e.TransaLogin = data.TransaLogin;
									e.TransaRegDate = data.TransaRegDate;
								}
							})
							opcion++;
						}
					}else if(parseInt(Level) === 2){
						let opcion = 0;
						while(opcion < 3){
							state.myData[Index].GpoModuleChildJson.map(function(e){
								if(opcion === 1 && OrdenModificada === e.Order){
									e.Order = OrdenOriginal;
								}
								if(opcion === 2 && e.GpoModuleId === data.GpoModuleId){
									e.Order = data.Order;
									e.TransaLogin = data.TransaLogin;
									e.TransaRegDate = data.TransaRegDate;
								}
							})
							opcion++;
						}
						
					}

					state.apiStateForm = ENUM.LOADED; 
				}else{
					console.log(response);
					state.apiStateForm = ENUM.ERROR; 
				}
            }catch(error){
				console.log(error);
				state.messageMutation = error;
				state.apiStateForm = ENUM.ERROR; 
            }
        },
        postGrupoModuloOrdenFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR; 
		},

		//Listar los grupos modulos
		getGrupoModulolistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getGrupoModulolistSuccess(state, { GrupoModulo }) {
            try{
				if(GrupoModulo.status === 200){
					state.myData = GrupoModulo.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(GrupoModulo);
					state.apiState = ENUM.ERROR;
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
		},
		getGrupoHijoModulolistSuccess(state, { GrupoModuloHijo }) {
            try{
				if(GrupoModuloHijo.status === 200){
					state.myDataHijo = GrupoModuloHijo.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(GrupoModuloHijo);
					state.apiState = ENUM.ERROR;
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getGrupoModulolistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
		},

		//Registrar un grupo modulo mutation
		postregisterGrupoModuloRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		postregisterGrupoModuloSuccess (state, { information }) {
			try{
				state.messageError = information[0].Response;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.messageError = error;
				state.apiStateForm = ENUM.ERROR;
			}
		},
		postregisterGrupoModuloFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},
  	}
}
