export const planificacionEstibaErrores = {
  namespaced: true,
  state: {
    actList: ''
  },
  mutations: {
    set (state, value) {
      state.actList = value
    }
  }
}
