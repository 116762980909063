import ENUM from './enum';
import service from '@/_services/service';
import { DateFormater ,validURL } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';

export const maquina = {
    namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
        formReset:0,
        tabIndex:'tab1',
        filtroConditionId:'',
        filtroMachineTp:'',
        MachineId:0,
        messageError:'',
        CollapseMachine:false,
        CollapseCarga:false,
        tabFormIndex:'tabForm1',
        idState:0,
        myDataMachine:[],
        myDataPhotoMachine:[],
        myDataMachineTp:[],
        myDataMachineCondition:[],
        NewMachineName: '',
        NewMachineId: '',
        machineRoute: '',
        myDataBrand:[],
        myDataModel:[],
        myDataStatus:[],
        myDataUnityMesure:[],
        myDataMachineSuplier:[],
        tabMachine: 0,
    },
    getters: {
        myDataMachineConditiontable: state => {
            let chart = [];
            if(state.myDataMachineCondition.length !== 0){
                state.myDataMachineCondition.sort(function (a, b) {
                    if (a.FgActMachineCondition < b.FgActMachineCondition) return 1;
                    else if (a.FgActMachineCondition > b.FgActMachineCondition) return -1;
                    else {
                        if (a.MachineConditionName > b.MachineConditionName) {
                            return 1;
                        }
                        if (a.MachineConditionName < b.MachineConditionName) {
                            return -1;
                        }
                        return 0;
                    }
				})
                return state.myDataMachineCondition.map((item) => Object.assign({}, item, {
					RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActMachineCondition,
                    _classes: (( item.FgActMachineCondition === false ) ? 'table-danger' : ((state.filtroConditionId !== '' && state.filtroConditionId === item.MachineConditionId) ? 'table-primary' : '' ))
                }));
            }
            return chart;
        },
        myDataMachineTptable: state => {
            let chart = [];
            if(state.myDataMachineTp.length !== 0){
                state.myDataMachineTp.sort(function (a, b) {
                    if (a.FgActTpMachine < b.FgActTpMachine) return 1;
                    else if (a.FgActTpMachine > b.FgActTpMachine) return -1;
                    else {
                        if (a.TpMachineName > b.TpMachineName) {
                            return 1;
                        }
                        if (a.TpMachineName < b.TpMachineName) {
                            return -1;
                        }
                        return 0;
                    }
				})
                return state.myDataMachineTp.map((item) => Object.assign({}, item, {
					RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActTpMachine,
                    _classes: (( item.FgActTpMachine === false ) ? 'table-danger' : ((state.filtroMachineTp !== '' && state.filtroMachineTp === item.TpMachineId) ? 'table-primary' : '' ))
                }));
            }
            return chart;
        },
        myDataMachinetable: state => {
            let chart = [];
            if(state.myDataMachine.length !== 0){
                state.myDataMachine.sort(function (a, b) {
                    if (a.FgActMachine < b.FgActMachine) return 1;
                    else if (a.FgActMachine > b.FgActMachine) return -1;
                    else {
                        if (a.MachineName > b.MachineName) {
                            return 1;
                        }
                        if (a.MachineName < b.MachineName) {
                            return -1;
                        }
                        return 0;
                    }
				})
                return state.myDataMachine.map((item) => Object.assign({}, item, {
					RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActMachine,
                    //operation: item.FgOperation? true : false,
                    _classes: ( item.FgActMachine === false ) ? 'table-danger' : ''
                }));
            }
            return chart;
        },
        myDataPhotoMachinetable: state => {
            let chart = [];
            if(state.myDataPhotoMachine.length !== 0){
                state.myDataPhotoMachine.sort(function (a, b) {
                    if (a.FgActMachineDoc < b.FgActMachineDoc) return 1;
                    else if (a.FgActMachineDoc > b.FgActMachineDoc) return -1;
                    else {
                        if (a.MachineDocDesc > b.MachineDocDesc) {
                            return 1;
                        }
                        if (a.MachineDocDesc < b.MachineDocDesc) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataPhotoMachine.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    Icon: (validURL(item.RouteMachineDoc) || item.RouteMachineDoc === undefined || item.RouteMachineDoc === null || item.RouteMachineDoc === '') ? item.RouteMachineDoc : `${DataStorage.getConnection()}${item.RouteMachineDoc.replace('public/','', null, 'i')}`,
                    status: item.FgActMachineDoc,
                    _classes: (( item.FgActMachineDoc === false ) ? 'table-danger' : '') 
                }));
            }
            return chart;
        },
    },
    actions: {
        getAlllist({commit},{ CompanyBranchId }){
            commit('getAlllistRequest');
            service.ejecutar('GET', 'TpMachine-list', { Filter: 'ALL' }, '')
            .then(
                tpmachineall => {
                    service.ejecutar('GET', 'MachineCondition-list', { Filter: 'ALL' }, '')
                    .then(
                        machineconditionall => {
                            const valores = {
                                "MachineJson":[
                                    {
                                        "CompanyBranchId":CompanyBranchId, 
                                        "TpMachineId":"",
                                        "MachineConditionId":""
                                    }
                                ]
                            }


                            service.ejecutar('GET', 'Machine-list', { 
                                MachineJson:valores,Filter: 'ALL' }, '')
                            .then(
                                machineall => {
                                    commit('getAlllistSuccess', { tpmachineall,machineconditionall,machineall });
                                },
                                error => {
                                    commit('messageMutation', error)
                                    commit('getAlllistFailure', error)
                                    //dispatch('alert/error', error, { root: true });
                                }
                            );
                        },
                        error => {
                            commit('messageMutation', error)
                            commit('getAlllistFailure', error)
                            //dispatch('alert/error', error, { root: true });
                        }
                    );
                },
                error => {
                    commit('messageMutation', error)
                    commit('getAlllistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        getTpMachinelist({commit}){
            commit('getTpMachinelistRequest');
            service.ejecutar('GET', 'TpMachine-list', { Filter: 'ACTIVO' }, '')
            .then(
                tpmachineall => {
                    commit('getTpMachinelistSuccess', { tpmachineall });
                },
                error => {
                    commit('messageMutation', error)
                    commit('getTpMachinelistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        sendDataTpMachine ({ commit,dispatch }, { TpMachineId,TpMachineName,TpMachineDs,FgActTpMachine }) {
			commit('sendDataTpMachineRequest');
			try {
				let method = 'POST';
                let valores = {};
                let link = 'TpMachine-insert';
                if(TpMachineId === 0){
                    valores = {
						TpMachineName:TpMachineName,
						TpMachineDs:TpMachineDs
                    }
                }else{
                    method = 'PUT';
                    link = 'TpMachine-update';
                    valores = {
						TpMachineId:TpMachineId,
                        TpMachineName:TpMachineName,
						TpMachineDs:TpMachineDs,
                        Status: FgActTpMachine ? 1 : 0
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'TpMachineJson' })
				.then(
					response => {
						if(response.data.status === 200){
							const information = response.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('sendDataTpMachineFailure', information[0].Response)
							}else{
								commit('sendDataTpMachineSuccess', { information });
                                dispatch('maquina/getTpMachinelist', '', { root: true });
							}
						}else{
							commit('sendDataTpMachineFailure', response.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('sendDataTpMachineFailure', error)
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('sendDataTpMachineFailure', error)
			}
				
		},
        getConditionMachinelist({commit}){
            commit('getConditionMachinelistRequest');
            service.ejecutar('GET', 'MachineCondition-list', { Filter: 'ACTIVO' }, '')
            .then(
                machineconditionall => {
                    commit('getConditionMachinelistSuccess', { machineconditionall });
                },
                error => {
                    commit('messageMutation', error)
                    commit('getConditionMachinelistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        sendDataConditionMachine ({ commit,dispatch }, { MachineConditionId,MachineConditionName,FgActMachineCondition }) {
			commit('sendDataConditionMachineRequest');
			try {
				let method = 'POST';
                let valores = {};
                let link = 'MachineCondition-insert';
                if(MachineConditionId === 0){
                    valores = {
						MachineConditionName:MachineConditionName
                    }
                }else{
                    method = 'PUT';
                    link = 'MachineCondition-update';
                    valores = {
						MachineConditionId:MachineConditionId,
                        MachineConditionName:MachineConditionName,
                        Status: FgActMachineCondition ? 1 : 0
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'MachineConditionJson' })
				.then(
					response => {
						if(response.data.status === 200){
							const information = response.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('sendDataConditionMachineFailure', information[0].Response)
							}else{
								commit('sendDataConditionMachineSuccess', { information });
                                dispatch('maquina/getConditionMachinelist', '', { root: true });
							}
						}else{
							commit('sendDataConditionMachineFailure', response.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('sendDataConditionMachineFailure', error)
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('sendDataConditionMachineFailure', error)
			}
				
		},
        getConditionMachinelist({commit}){
            commit('getConditionMachinelistRequest');
            service.ejecutar('GET', 'MachineCondition-list', { Filter: 'ACTIVO' }, '')
            .then(
                machineconditionall => {
                    commit('getConditionMachinelistSuccess', { machineconditionall });
                },
                error => {
                    commit('messageMutation', error)
                    commit('getConditionMachinelistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        async getMachinelist({commit},{CompanyBranchId,MachineConditionId,TpMachineId}){
            const valores = {
                "MachineJson":[
                    {
                        "CompanyBranchId":CompanyBranchId, 
                        "TpMachineId":TpMachineId,
                        "MachineConditionId":MachineConditionId
                    }
                ]
            }
            await service.ejecutar('GET', 'Machine-list', { MachineJson:valores,Filter: 'ALL' }, '')
            .then(
                async machineall => {
                    await commit('getMachinelistSuccess', { machineall });
                },
                error => {
                    commit('messageMutation', error)
                    commit('getMachinelistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        //crear maquina
        sendDataMachine ({ commit }, { CompanyBranchId,MachineId,TpMachineId,MachineConditionId,ModelId,MachineName,ProcessStatusId,ClientTpId,
            Year,Cod,Serial,MaxCapacity,MaxRadio,UnitMeasureIdSC,UnitMeasureIdSD,SpeedCharge,SpeedDischarge,FgActMachine,FgOperation }) {
			commit('sendDataMachineRequest');
			try {
				let method = 'POST';
                let valores = {};
                let link = 'Machine-insert';
                let a=0;
                if(MachineId === 0){
                    a=1;
                    valores = {
						CompanyBranchId:CompanyBranchId,
                        TpMachineId:TpMachineId,
                        MachineConditionId:MachineConditionId,
                        ProcessStatusId:ProcessStatusId,
                        ClientTpId:ClientTpId,
                        ModelId:ModelId,
                        MachineName:MachineName,
                        Year:Year,
                        Cod:Cod,
                        Serial:Serial,
                        MaxCapacity:MaxCapacity,
                        MaxRadio:MaxRadio,
                        SpeedCharge:SpeedCharge,
                        UnitMeasureIdSC:UnitMeasureIdSC,
                        SpeedDischarge:SpeedDischarge,
                        UnitMeasureIdSD:UnitMeasureIdSD,
                        FgOperation:FgOperation
                    }
                }else{
                    method = 'PUT';
                    link = 'Machine-update';
                    valores = {
						MachineId:MachineId,
                        CompanyBranchId:CompanyBranchId,
                        TpMachineId:TpMachineId,
                        MachineConditionId:MachineConditionId,
                        ProcessStatusId:ProcessStatusId,
                        ClientTpId:ClientTpId,
                        ModelId:ModelId,
                        MachineName:MachineName,
                        Year:Year,
                        Cod:Cod,
                        Serial:Serial,
                        MaxCapacity:MaxCapacity,
                        MaxRadio:MaxRadio,
                        SpeedCharge:SpeedCharge,
                        UnitMeasureIdSC:UnitMeasureIdSC,
                        SpeedDischarge:SpeedDischarge,
                        UnitMeasureIdSD:UnitMeasureIdSD,
                        Status: FgActMachine ? 1 : 0,
                        FgOperation: FgOperation
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'MachineJson' })
				.then(
					response => {
						if(response.data.status === 200){
							const information = response.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('sendDataMachineFailure', information[0].Response)

							}else{
                                if(a === 1){//lista de fotos asociadas a una maq
                                commit('getDocMachinelistRequest');
                                    service.ejecutar('GET', 'MachineDoc-list', { MachineId: information[0].MachineId, Filter: 'ALL' }, '')
                                    .then(
                                        machinedocall => {
                                            commit('getDocMachinelistSuccess', { machinedocall });
                                        },
                                        error => {
                                            commit('messageMutation', error)
                                            commit('getDocMachinelistFailure', error)
                                            //dispatch('alert/error', error, { root: true });
                                        }
                                    );
                                }
                                commit('sendDataMachineSuccess', { information });
							}
						}else{
							commit('sendDataMachineFailure', response.data);
                            
						}
					},
					error => {
						commit('messageMutation', error)
						commit('sendDataMachineFailure', error)
					}

				);
			} catch (error) {
				commit('messageMutation', error)
				commit('sendDataMachineFailure', error)
			}
				
		},

        getDocMachinelist({commit}, { MachineId }){
            commit('getDocMachinelistRequest');
            service.ejecutar('GET', 'MachineDoc-list', { MachineId: (MachineId === undefined || MachineId === null) ? '': MachineId, Filter: 'ALL' }, '')
            .then(
                machinedocall => {
                    commit('getDocMachinelistSuccess', { machinedocall });
                },
                error => {
                    commit('messageMutation', error)
                    commit('getDocMachinelistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        sendDataDocMachine ({ commit,dispatch }, { IdMachinePhoto,MachineId,MachinePhotoDs,MachinePhotoRoute,Image_original,FgActPhotoMachine }) {
			commit('sendDataDocMachineRequest');
			try {
				let method = 'POST';
                let valores = {};
                let link = 'MachineDoc-insert';
                if(IdMachinePhoto === 0){
                    valores = {
                        MachineId:MachineId,
						MachineDocDesc:MachinePhotoDs,
                        RouteMachineDoc:'',
                    }
                }else{
                    method = 'PUT';
                    link = 'MachineDoc-update';
                    valores = {
                        MachineDocId:IdMachinePhoto,
                        MachineId:MachineId,
						MachineDocDesc:MachinePhotoDs,
                        RouteMachineDoc:'',
                        Status: FgActPhotoMachine ? 1 : 0
                    }
                }
                (async () => {
					try{
                        if(typeof MachinePhotoRoute !== 'string'  && MachinePhotoRoute !== null && MachinePhotoRoute !== undefined && MachinePhotoRoute !== ''){
							const response = await service.file(link,MachinePhotoRoute,Image_original);
							const info = response.data.data;
							valores.RouteMachineDoc = info.files[0].path;
						}else{
                            valores.RouteMachineDoc = MachinePhotoRoute;
                        }

                        service.ejecutar(method, link, valores, { root: 'MachineDocJson' })
                        .then(
                            response => {
                                if(response.data.status === 200){
                                    const information = response.data.data;
                                    if(information[0].id === ""){
                                        commit('messageMutation', information[0].Response)
                                        commit('sendDataDocMachineFailure', information[0].Response)
                                    }else{
                                        commit('sendDataDocMachineSuccess', { information });
                                        dispatch('maquina/getDocMachinelist', { MachineId }, { root: true });
                                    }
                                }else{
                                    commit('sendDataDocMachineFailure', response.data);
                                }
                            },
                            error => {
                                commit('messageMutation', error)
                                commit('sendDataDocMachineFailure', error)
                            }
                        );
                    } catch (error) {
                        commit('messageMutation', error)
                        commit('sendDataDocMachineFailure', error)
                    }
                })();
			} catch (error) {
				commit('messageMutation', error)
				commit('sendDataDocMachineFailure', error)
			}
				
		},
        sendDataCargoMachine ({ commit,dispatch }, { MachineCargoJson }) {
			commit('sendDataCargoMachineRequest');
			try {
				let method = 'PUT';
                let valores = {};
                let link = 'MachineTpCargo-update';
                
                service.ejecutar(method, link, MachineCargoJson, { root: 'MachineTpCargoJson' })
				.then(
					response => {
						if(response.data.status === 200){
							const information = response.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('sendDataCargoMachineFailure', information[0].Response)
							}else{
								commit('sendDataCargoMachineSuccess', { information });
                                dispatch('maquina/getConditionMachinelist', '', { root: true });
							}
						}else{
							commit('sendDataCargoMachineFailure', response.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('sendDataCargoMachineFailure', error)
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('sendDataCargoMachineFailure', error)
			}
		},
    },
    mutations: {
        messageMutation (state, value ) {
            state.messageError = value;
        },
        collapseRegister(state, value){
            state.CollapseMachine = value;
            if(value){
                //document.getElementById("tabForm1").click();
                state.tabFormIndex = "tabForm1"; 
            }
        },
        collapseCarga(state,value){
            state.CollapseCarga = value;
        },
        //Listar TODOS
        getAlllistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getAlllistSuccess(state, { tpmachineall,machineconditionall,machineall }) {
            try{
				if(tpmachineall.status === 200 && machineconditionall.status === 200 && machineall.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataMachineTp = tpmachineall.data.data;
                    state.myDataMachineCondition = machineconditionall.data.data;
                    state.myDataMachine = machineall.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(machineall);
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getAlllistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        //listar condicion maquina
        getConditionMachinelistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getConditionMachinelistSuccess(state, { machineconditionall }) {
            try{
                if(machineconditionall.status){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataMachineCondition = machineconditionall.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(machineconditionall);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getConditionMachinelistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        //registrar o actualizar condicion de maquinas
		sendDataConditionMachineRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		sendDataConditionMachineSuccess (state, { information }) {
			try{
                state.formReset = 1;
                state.messageError = information[0].Response;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
		},
		sendDataConditionMachineFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},

        //listar tipos de maquinas
        getTpMachinelistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getTpMachinelistSuccess(state, { tpmachineall }) {
            try{
				if(tpmachineall.status){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataMachineTp = tpmachineall.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(tpmachineall);
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getTpMachinelistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        //registrar o actualizar tipo de maquinas
		sendDataTpMachineRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		sendDataTpMachineSuccess (state, { information }) {
			try{
                state.formReset = 2;
                state.messageError = information[0].Response;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
		},
		sendDataTpMachineFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},

        //listar maquinas
        getMachinelistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getMachinelistSuccess(state, { machineall }) {
            try{
				if(machineall.status){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataMachine = machineall.data.data;
				}else{
					
				}
            }catch(error){

            }
        },
        getMachinelistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        //registrar o actualizar maquinas
		sendDataMachineRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		sendDataMachineSuccess (state, { information }) {
			try{
                state.formReset = 3;
                state.NewMachineId = information[0].MachineId;
                state.idState = information[0].MachineId;
                state.NewMachineName = information[0].MachineName;
                state.messageError = information[0].Response;
                if(state.CollapseMachine)
                    state.tabFormIndex = "tabForm2"; 
                   // document.getElementById("tabForm2").click();


				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
		},
		sendDataMachineFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},

        //listar documentos de maquinas
        getDocMachinelistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getDocMachinelistSuccess(state, { machinedocall }) {
            try{
                if(machinedocall.status){
                    state.myDataPhotoMachine = machinedocall.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(machinedocall);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getDocMachinelistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        //registrar o actualizar tipo de maquinas
		sendDataDocMachineRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		sendDataDocMachineSuccess (state, { information }) {
			try{
                state.formReset = 4;
                state.messageError = information[0].Response;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
		},
		sendDataDocMachineFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},
        //registrar o actualizar asociar cargo de maquinas
		sendDataCargoMachineRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		sendDataCargoMachineSuccess (state, { information }) {
			try{
                state.CollapseCarga = false;
                state.messageError = information[0].Response;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
		},
		sendDataCargoMachineFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},
    }
}