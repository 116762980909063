import ENUM from './enum';
import service from '@/_services/service';
import { DateFormater ,validURL } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';


export const implemento = {
    namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
        idImplemento:0,
        idGpoImplemento:0,
        messageError:'',
        formReset:0,
        myDataImplemento:[],
        myDataGpoImplemento:[],
        modalGroup:false,
        modalImplement:false,
        implementRoute: '',
    },
    getters: {
        myDataImplementotable: state => {
            let chart = [];
            if(state.myDataImplemento.length !== 0){
                state.myDataImplemento.sort(function (a, b) {
                    
                    if (a.FgActImplement < b.FgActImplement) return 1;
                    else if (a.FgActImplement > b.FgActImplement) return -1;
                    else {
                        if (a.ImplementName > b.ImplementName) {
                            return 1;
                        }
                        if (a.ImplementName < b.ImplementName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataImplemento.map((item) => Object.assign({}, item, {
                    clientName: item.ClientTpId ? item.ClientName : 'N/A',
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    RutaImplemento: (validURL(item.ImplementRoute) || item.ImplementRoute === undefined || item.ImplementRoute === null || item.ImplementRoute === '') ? item.ImplementRoute : `${DataStorage.getConnection()}${item.ImplementRoute.replace('public/','', null, 'i')}`,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActImplement,
                    _classes: (( item.FgActImplement === false ) ? 'table-danger' : '' ) 
                }));
            }
            return chart;
        },
        myDataGpoImplementotable: state => {
            let chart = [];
            if(state.myDataGpoImplemento.length !== 0){
                state.myDataGpoImplemento.sort(function (a, b) {
                    if (a.FgActGpoImplement < b.FgActGpoImplement) return 1;
                    else if (a.FgActGpoImplement > b.FgActGpoImplement) return -1;
                    else {
                        if (a.NbGpoImplemento > b.NbGpoImplemento) {
                            return 1;
                        }
                        if (a.NbGpoImplemento < b.NbGpoImplemento) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataGpoImplemento.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActGpoImplement,
                    _classes: (( item.FgActGpoImplement === false ) ? 'table-danger' : '' ) 
                }));
            }
            return chart;
        },
    },
    actions: {
        getGpoImplementolist({commit,dispatch}, { filtro , type}){
            commit('getGpoImplementolistRequest');
            service.ejecutar('GET', 'GpoImplement-list',  { Filter: filtro} , '').then(
                GpoImplementos => {
                    commit('getGpoImplementolistSuccess', { GpoImplementos })
                    if(type === 1){
                        dispatch('implemento/getImplementolist', { idGpoImplemento: null, filtro : 'ALL' }, { root: true });
                    }
                },
                error => {
                    commit('messageMutation', error)
                    commit('getGpoImplementolistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        getImplementolist({commit}, { idGpoImplemento,filtro }){
            commit('getImplementolistRequest');

            service.ejecutar('GET', 'Implement-list',  { Filter: (filtro === undefined || filtro === null) ? 'ALL': filtro,GpoImplementId: (idGpoImplemento === undefined || idGpoImplemento === null) ? '': idGpoImplemento} , '')
            .then(
                Implementos => commit('getImplementolistSuccess', { Implementos }),
                error => {
                    commit('messageMutation', error)
                    commit('getImplementolistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        postregisterGpoImplemento ({ commit }, { IdGpoImplemento,NbGpoImplemento,FgActGpoImplemento }) {
            commit('postregisterGpoImplementoRequest');
            try{
                let method = 'POST';
                let valores = {};
                let link = 'GpoImplement-insert';
                if(IdGpoImplemento === 0){
                    valores = {
						GpoImplementName:NbGpoImplemento,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'GpoImplement-update';
                    valores = {
						GpoImplementId:IdGpoImplemento,
                        GpoImplementName:NbGpoImplemento,
                        Status: FgActGpoImplemento ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'GpoImplementJson' })
                .then(
                    dataResponse => {
                        if(dataResponse.data.status === 200){
                            const information = dataResponse.data.data;
                            if(information[0].Id === ""){
                                commit('messageMutation', information[0].Response)
                                commit('postregisterGpoImplementoFailure', information[0].Response)
                            }else{
                                commit('messageMutation', information[0].Response)
                                commit('postregisterGpoImplementoSuccess', { information })
                            }
                        }else{
                            commit('postregisterGpoImplementoFailure', dataResponse.data);
                        }
                    },
                    error => {
                        commit('messageMutation', error)
                        commit('postregisterGpoImplementoFailure', error)
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                commit('messageMutation', error)
                commit('postregisterGpoImplementoFailure', error)
            }
            
        },
        postregisterImplemento ({ commit }, { IdImplemento,IdGpoImplemento,NbImplemento,DsImplemento,FgActImplemento,RutaImplemento,Image_original }) {
            commit('postregisterImplementoRequest');
            try{
                let method = 'POST';
                let valores = {};
				let link = 'Implement-insert';
				if(IdImplemento === 0){
                    valores = {
                        GpoImplementId:IdGpoImplemento,
                        ImplementName:NbImplemento,
                        ImplementDs:DsImplemento,
                        ImplementRoute:RutaImplemento,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'Implement-update';
                    valores = {
						ImplementId:IdImplemento,
                        GpoImplementId:IdGpoImplemento,
                        ImplementName:NbImplemento,
                        ImplementDs:DsImplemento,
                        ImplementRoute:RutaImplemento,
						Status: FgActImplemento ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }
                (async () => {
                    try {
                        if(typeof RutaImplemento !== 'string' && RutaImplemento !== null && RutaImplemento !== undefined && RutaImplemento !== ''){
                            const response = await service.file(link,RutaImplemento,Image_original);
                            const info = response.data.data;
                            valores.ImplementRoute = info.files[0].path;
                        }
    
                        service.ejecutar(method, link, valores, { root: 'ImplementJson' })
                        .then(
                            dataResponse => {
                                if(dataResponse.data.status === 200){
                                    const information = dataResponse.data.data;
                                    if(information[0].Id === ""){
                                        commit('messageMutation', information[0].Response)
                                        commit('postregisterImplementoFailure', information[0].Response)
                                    }else{
                                        commit('messageMutation', information[0].Response)
                                        commit('postregisterImplementoSuccess', { information })
                                    }
                                }else{
                                    commit('postregisterImplementoFailure', dataResponse.data);
                                }
                            },
                            error => {
                                commit('messageMutation', error)
                                commit('postregisterImplementoFailure', error)
                                //dispatch('alert/error', error, { root: true });
                            }
                        ); 
                    } catch (error) {
                        commit('messageMutation', error)
                        commit('postregisterImplementoFailure', error)
                    }
                })();
            } catch (error) {
                commit('messageMutation', error)
                commit('postregisterImplementoFailure', error)
            }
        }
    },
    mutations: {
        asignarid (state ) {
            state.idGpoImplemento = 0;
            state.idImplemento = 0;
        },
        messageMutation (state, value ) {
            state.messageError = value;
		},
        mutationModalGroup (state,  action ) {
            state.modalGroup = action;
        },
        mutationModalImplement (state,  action ) {
            state.modalImplement = action;
        },
        //Listar grupos implementos
        getGpoImplementolistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getGpoImplementolistSuccess(state, { GpoImplementos }) {
            try{
                if(GpoImplementos.status === 200){
                    state.myDataGpoImplemento = GpoImplementos.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(GpoImplementos);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getGpoImplementolistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        //Listar implementos
        getImplementolistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getImplementolistSuccess(state, { Implementos }) {
            try{
                if(Implementos.status === 200){
                    state.myDataImplemento = Implementos.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(Implementos);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getImplementolistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        
        //Registrar o actualizar grupo implemento
        postregisterGpoImplementoRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        postregisterGpoImplementoSuccess (state, { information }) {
            try{
                if(state.idGpoImplemento === 0){
                    state.idGpoImplemento = information[0].GpoImplementId;
                    state.myDataGpoImplemento.push({
                        GpoImplementId:information[0].GpoImplementId,
                        GpoImplementName:information[0].GpoImplementName,
                        FgActGpoImplement:information[0].FgActGpoImplement,
                        Status:information[0].Status,
                        TransaUserId:information[0].TransaUserId,
                        TransaLogin:information[0].TransaLogin,
                        TransaRegDate:information[0].TransaRegDate,
                        insertedid:information[0].insertedid,
                    })
                }else{
                    state.idGpoImplemento = information[0].GpoImplementId;
                    let x = state.myDataGpoImplemento.map(function(e) {
                        return e.GpoImplementId; 
                    }).indexOf(state.idGpoImplemento);
                    if(x !== -1){
                        state.myDataGpoImplemento[x].GpoImplementId = information[0].GpoImplementId;
                        state.myDataGpoImplemento[x].GpoImplementName = information[0].GpoImplementName;
                        state.myDataGpoImplemento[x].FgActGpoImplement = information[0].FgActGpoImplement;
                        state.myDataGpoImplemento[x].Status = information[0].Status;
                        state.myDataGpoImplemento[x].TransaUserId = information[0].TransaUserId;
                        state.myDataGpoImplemento[x].TransaLogin = information[0].TransaLogin;
                        state.myDataGpoImplemento[x].TransaRegDate = information[0].TransaRegDate;
                        state.myDataGpoImplemento[x].insertedid = information[0].insertedid;

                        state.myDataGpoImplemento.map(function(e) {
                            if(e.GpoImplementId === information[0].GpoImplementId){
                                e.GpoImplementName = information[0].GpoImplementName;
                            }
                        }); 
                    }
                }
                state.formReset = 1;
                state.messageError = information[0].Response;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                state.apiStateForm = ENUM.ERROR;
            }
        },
        postregisterGpoImplementoFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR;
            state.apiStateForm = ENUM.ERROR;
        },
        
        //Registrar o actualizar un implemento
        postregisterImplementoRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        postregisterImplementoSuccess (state, { information }) {
            try{
                if(state.idImplemento === 0){
                    state.idImplemento = information[0].ImplementId;
                    state.myDataImplemento.push({
                        ImplementId:information[0].ImplementId,
                        GpoImplementId:information[0].GpoImplementId,
                        GpoImplementName:information[0].GpoImplementName,
                        ImplementName:information[0].ImplementName,
                        ImplementDs:information[0].ImplementDs,
                        ImplementRoute:information[0].ImplementRoute,
                        FgActImplement:information[0].FgActImplement,
                        Status:information[0].Status,
                        TransaUserId:information[0].TransaUserId,
                        TransaLogin:information[0].TransaLogin,
                        TransaRegDate:information[0].TransaRegDate,
                        insertedid:information[0].insertedid,
                    })
                }else{
                    state.idImplemento = information[0].ImplementId;
                    let x = state.myDataImplemento.map(function(e) {
                        return e.ImplementId; 
                    }).indexOf(state.idImplemento);
                    if(x !== -1){
                        state.myDataImplemento[x].ImplementId = information[0].ImplementId;
                        state.myDataImplemento[x].GpoImplementId = information[0].GpoImplementId;
                        state.myDataImplemento[x].GpoImplementName = information[0].GpoImplementName;
                        state.myDataImplemento[x].ImplementName = information[0].ImplementName;
                        state.myDataImplemento[x].ImplementDs = information[0].ImplementDs;
                        state.myDataImplemento[x].ImplementRoute = information[0].ImplementRoute;
                        state.myDataImplemento[x].FgActImplement = information[0].FgActImplement;
                        state.myDataImplemento[x].Status = information[0].Status;
                        state.myDataImplemento[x].TransaUserId = information[0].TransaUserId;
                        state.myDataImplemento[x].TransaLogin = information[0].TransaLogin;
                        state.myDataImplemento[x].TransaRegDate = information[0].TransaRegDate;
                        state.myDataImplemento[x].insertedid = information[0].insertedid;
                    }
                }
                state.formReset = 2;
                state.messageError = information[0].Response;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.apiStateForm = ENUM.ERROR;
            }
        },
        postregisterImplementoFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },
    }
}
