import ENUM from './enum';

export const visitas = {
    namespaced: true,
  	state: {
        VisitId: '',
        TpVesselMasterId: '',
        ItineraryId:0,
        tabIndex:0,
        dropItemAgenda:0,
        dropItemReport:0,
        dropItemOrder:0,
        dropItemDratf: 0,
        dropItemTime: 0,
        dropItemElectronic: 0,
        StowagePlanningId:0,
        apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
        messageError:'',
        itinerarySelected:{},
        collapseVisits:false,
        collapseTerminal:false,
        modalContainerData:false,
        EdiFileId: 0,
        EdiFileInfo: null,
        bulkCarrier: 0, // 1 = buque tipo porta contenedores, 2 = buque tipo bulk carrier
        StowageFactor: 0,
        OrderId: 0,
        collapseSidebar: false,
    },
    getters: {},
    actions: {},
    mutations: {
        asignarid (state, id ) {
            
		},
		messageMutation (state, value ) {
            state.messageError = value;
        },
    }
}