import ENUM from './enum';
import service from '@/_services/service';
import { DateFormater ,validURL } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';


export const marcamodelo = {
    namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
        idMarca:0,
        idModelo:0,
        formReset:0,
        messageError:'',
        myDataMarcas:[],
        myDataModelos:[],
        modalBrandUpdated:false,
        modalModelUpdated:false,
        BrandRoute: '',
        ModelRoute: '',
        tabIndex:0,
    },
    getters: {
        myDataMarcatable: state => {
            let chart = [];
            if(state.myDataMarcas.length !== 0){
                state.myDataMarcas.sort(function (a, b) {
                    if (a.FgActBrand < b.FgActBrand) return 1;
                    else if (a.FgActBrand > b.FgActBrand) return -1;
                    else {
                        if (a.BrandName > b.BrandName) {
                            return 1;
                        }
                        if (a.BrandName < b.BrandName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataMarcas.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    BrandRoute: (validURL(item.BrandRoute) || item.BrandRoute === undefined || item.BrandRoute === null || item.BrandRoute === '') ? item.BrandRoute : `${DataStorage.getConnection()}${item.BrandRoute.replace('public/','', null, 'i')}`,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    FgMachineDs: item.FgMachineDs,
                    status: item.FgActBrand,

                    _classes: (( item.FgActBrand === false ) ? 'table-danger' : ((state.idMarca !== 0 && state.idMarca === item.BrandId) ? 'table-primary' : '' )) 
                }));
            }
            return chart;
        },
        myDataModelotable: state => {
            let chart = [];
            if(state.myDataModelos.length !== 0){
                state.myDataModelos.sort(function (a, b) {
                    if (a.FgActModel < b.FgActModel) return 1;
                    else if (a.FgActModel > b.FgActModel) return -1;
                    else {
                        if (a.ModelName > b.ModelName) {
                            return 1;
                        }
                        if (a.ModelName < b.ModelName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataModelos.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    BrandRoute: (validURL(item.BrandRoute) || item.BrandRoute === undefined || item.BrandRoute === null || item.BrandRoute === '') ? item.BrandRoute : `${DataStorage.getConnection()}${item.BrandRoute.replace('public/','', null, 'i')}`,
                    ModelRoute: (validURL(item.ModelRoute) || item.ModelRoute === undefined || item.ModelRoute === null || item.ModelRoute === '') ? item.ModelRoute : `${DataStorage.getConnection()}${item.ModelRoute.replace('public/','', null, 'i')}`,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActModel,
                    _classes: (( item.FgActModel === false ) ? 'table-danger' : ((state.idModelo !== 0 && state.idModelo === item.ModelId) ? 'table-primary' : '' )) 
                }));
            }
            return chart;
        },
    },
    actions: {
        getMarcalist({commit,dispatch}, { filtro , type}){
            commit('getMarcalistRequest');

            service.ejecutar('GET', 'Brand-list',  { Filter: filtro} , '')
            .then(
                Marcas => {
                    commit('getMarcalistSuccess', { Marcas })
                    if(type === 1){
                        dispatch('marcamodelo/getModelolist', { idMarca: null, filtro : 'ALL' }, { root: true });
                    }
                },
                error => {
                    commit('getModelolistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        getModelolist({commit}, { idMarca,filtro }){
            commit('getModelolistRequest');
            service.ejecutar('GET', 'Model-list',  { Filter: filtro, BrandId:(idMarca === undefined || idMarca === null) ? '': idMarca} , '')
            .then(
                Modelos => commit('getModelolistSuccess', { Modelos }),
                error => {
                    commit('getModelolistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        postregisterMarca ({ commit }, { IdMarca,NbMarca,FgDevice,FgMachine,FgActMarca,RutaMarca,Image_original }) {
            commit('postregisterMarcaRequest');
            try {
                let method = 'POST';
                let valores = {};
                let link = 'Brand-insert';
                if(IdMarca === 0){
                    valores = {
						BrandRoute:RutaMarca,
                        BrandName:NbMarca,
                        FgDevice:FgDevice ? 1 : 0, 
                        FgMachine:FgMachine ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'Brand-update';
                    valores = {
						BrandId:IdMarca,
                        BrandRoute:RutaMarca,
                        BrandName:NbMarca,
                        Status: FgActMarca ? 1 : 0,
                        FgDevice:FgDevice ? 1 : 0, 
                        FgMachine:FgMachine ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }
                
                (async () => {
                    try {
                        if(typeof RutaMarca !== 'string' && RutaMarca !== null && RutaMarca !== undefined && RutaMarca !== ''){
                            
                            const response = await service.file(link,RutaMarca,Image_original);
                            const info = response.data.data;
                            valores.BrandRoute = info.files[0].path;
                        }
                        service.ejecutar(method, link, valores, { root: 'BrandJson' })
                        .then(
                            dataResponse => {
                                if(dataResponse.data.status === 200){
                                    const information = dataResponse.data.data;
                                    if(information[0].id === ""){
                                        commit('messageMutation', information[0].Response)
                                        commit('postregisterMarcaFailure', information[0].Response)
                                    }else{
                                        commit('postregisterMarcaSuccess', { information })
                                         
                                    }
                                }else{
                                    commit('postregisterMarcaFailure', dataResponse.data);
                                }
                            },
                            error => {
                                commit('messageMutation', error)
                                commit('postregisterMarcaFailure', error)
                                //dispatch('alert/error', error, { root: true });
                            }
                        );
                    } catch (error) {
                        commit('messageMutation', error)
                        commit('postregisterMarcaFailure', error)
                    }
                })();
            } catch (error) {
                commit('messageMutation', error)
                commit('postregisterMarcaFailure', error)
            }
        },
        postregisterModelo ({ commit }, { IdMarca,IdModelo,NbModelo,FgActModelo,RutaModelo,Image_original }) {
            commit('postregisterModeloRequest');
            try {
                let method = 'POST';
                let valores = {};
                let link = 'Model-insert';
                if(IdModelo === 0){
                    valores = {
                        BrandId:IdMarca,
						ModelRoute:RutaModelo,
                        ModelName:NbModelo,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'Model-update';
                    valores = {
                        ModelId:IdModelo,
						BrandId:IdMarca,
						ModelRoute:RutaModelo,
                        ModelName:NbModelo,
                        Status: FgActModelo ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }
                (async () => {
                    try {
                        if(typeof RutaModelo !== 'string' && RutaModelo !== null && RutaModelo !== undefined && RutaModelo !== ''){
                            const response = await service.file(link,RutaModelo,Image_original);
                            const info = response.data.data;
                            valores.ModelRoute = info.files[0].path;
                        }
                
                        service.ejecutar(method, link, valores, { root: 'ModelJson' })
                        .then(
                            dataResponse => {
                                if(dataResponse.data.status === 200){
                                    const information = dataResponse.data.data;
                                    if(information[0].id === ""){
                                        commit('messageMutation', information[0].Response)
                                        commit('postregisterModeloFailure', information[0].Response)
                                    }else{
                                        commit('postregisterModeloSuccess', { information })
                                    }
                                }else{
                                    commit('postregisterModeloFailure', dataResponse.data);
                                }
                            },
                            error => {
                                commit('messageMutation', error)
                                commit('postregisterModeloFailure', error)
                                //dispatch('alert/error', error, { root: true });
                            }
                        );
                    } catch (error) {
                        commit('messageMutation', error)
                        commit('postregisterModeloFailure', error)
                    }
                })();
            } catch (error) {
                commit('messageMutation', error)
                commit('postregisterModeloFailure', error)
            }
            
        }
    },
    mutations: {
        asignarid (state ) {
            state.idMarca = 0;
            state.idModelo = 0;
        },
        messageMutation (state, value ) {
            state.messageError = value;
		},
        mutationModalBrand (state,  action ) {
            state.modalBrandUpdated = action;
        },
        mutationModalModel (state,  action ) {
            state.modalModelUpdated = action;
        },

        //Listar marcas
        getMarcalistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getMarcalistSuccess(state, { Marcas }) {
            try{
                if(Marcas.status === 200){
                    state.myDataMarcas = Marcas.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(Marcas);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getMarcaslistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        //Listar modelos
        getModelolistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getModelolistSuccess(state, { Modelos }) {
            try{
                if(Modelos.status === 200){
                    state.myDataModelos = Modelos.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(Modelos);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getModelolistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        
        //Registrar o actualizar un marca
        postregisterMarcaRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        postregisterMarcaSuccess (state, { information }) {
            try{
                if(state.idMarca === 0){
                    state.idMarca = information[0].BrandId;
                    state.myDataMarcas.push({
                        TransaRegDate:information[0].TransaRegDate,
                        FgActBrand:information[0].FgActBrand,
                        BrandId:information[0].BrandId,
                        BrandName:information[0].BrandName,
                        Status:information[0].Status,
                        TransaUserId:information[0].TransaUserId,
                        TransaLogin:information[0].TransaLogin,
                        BrandRoute:information[0].BrandRoute,
                    })
                }else{
                    state.idMarca = information[0].BrandId;
                    let x = state.myDataMarcas.map(function(e) {
                        return e.BrandId; 
                    }).indexOf(state.idMarca);
                    if(x !== -1){
                        state.myDataMarcas[x].TransaRegDate = information[0].TransaRegDate;
                        state.myDataMarcas[x].FgActBrand = information[0].FgActBrand;
                        state.myDataMarcas[x].Status = information[0].Status;
                        state.myDataMarcas[x].BrandId = information[0].BrandId;
                        state.myDataMarcas[x].TransaUserId = information[0].TransaUserId;
                        state.myDataMarcas[x].TransaLogin = information[0].TransaLogin;
                        state.myDataMarcas[x].BrandName = information[0].BrandName;
                        state.myDataMarcas[x].BrandRoute = information[0].BrandRoute;

                        state.myDataModelos.map(function(e) {
                            if(e.BrandId === information[0].BrandId){
                                e.BrandName = information[0].BrandName;
                                e.BrandRoute = information[0].BrandRoute;
                            }
                        }); 
                    }
                }
                state.formReset = 1;
                state.messageError = information[0].Response;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.apiStateForm = ENUM.ERROR;
            }
        },
        postregisterMarcaFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR;
            state.apiStateForm = ENUM.ERROR;
        },
        
        //Registrar o actualizar un marca
        postregisterModeloRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        postregisterModeloSuccess (state, { information }) {
            try{
                if(state.idModelo === 0){
                    state.idModelo = information[0].ModelId;
                    state.myDataModelos.push({
                        TransaRegDate:information[0].TransaRegDate,
                        FgActModel:information[0].FgActModel,
                        ModelId:information[0].ModelId,
                        BrandId:information[0].BrandId,
                        BrandName:information[0].BrandName,
                        Status:information[0].Status,
                        TransaUserId:information[0].TransaUserId,
                        TransaLogin:information[0].TransaLogin,
                        ModelName:information[0].ModelName,
                        ModelRoute:information[0].ModelRoute,
                    })
                }else{
                    state.idModelo = information[0].ModelId;
                    let x = state.myDataModelos.map(function(e) {
                        return e.ModelId; 
                    }).indexOf(state.idModelo);
                    if(x !== -1){
                        state.myDataModelos[x].TransaRegDate = information[0].TransaRegDate;
                        state.myDataModelos[x].FgActModel = information[0].FgActModel;
                        state.myDataModelos[x].ModelId = information[0].ModelId;
                        state.myDataModelos[x].BrandId = information[0].BrandId;
                        state.myDataModelos[x].BrandName = information[0].BrandName;
                        state.myDataModelos[x].Status = information[0].Status;
                        state.myDataModelos[x].TransaUserId = information[0].TransaUserId;
                        state.myDataModelos[x].TransaLogin = information[0].TransaLogin;
                        state.myDataModelos[x].ModelName = information[0].ModelName;
                        state.myDataModelos[x].ModelRoute = information[0].ModelRoute;
                    }
                }
                state.formReset = 2;
                state.messageError = information[0].Response;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.apiStateForm = ENUM.ERROR;
            }
        },
        postregisterModeloFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },
    }
}
