export const Management = {
    namespaced: true,
  	state: {
        ManagementId:0,
        VisitId:0,
        CarruselTab:0,
        itinerarySelected:{},
        collapseManagement:false,
        bulkCarrier: 0, // 1 = buque tipo porta contenedores, 2 = buque tipo bulk carrier
    },
    getters: {},
    actions: {},
    mutations: {}
}