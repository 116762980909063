import { logo } from './logo';
import { softicoSet as customs} from './customIcons';
import { linearSet as icons } from '@coreui/icons-pro';
import { flagSet as flags } from '@coreui/icons-pro';

export const iconsSet = Object.assign(
  {},
  //{logo, pencil, checkAlt, x, excelDownload},
  customs,
  icons,
  flags
);
