import ENUM from "./enum";
import service from "@/_services/service";
import { DateFormater, validURL } from "@/_helpers/funciones";
import { isNull } from "lodash";
import Service from '@/_services/service';

export const yard = {
  namespaced: true,
  state: {
    yardSelected: {},
    tabIndex: 0,
    masterOfYardsTabIndex: 0,
    collapse: false,
    collapseBlocks: false,
    collapseHeightPlan: false,
    isHidden: false,
    dropItem: 0,
    heightJson: {},
    StackJson: {},
    YardHeightId: '',
    YardHeight: '',
    YardBlockId:'',
    YardBlock:'',
    fgOperative: false,

    //Steps Form
    current: 0,
    steps: 4,
    //Bay Data
    VesselId: '',
    bay: null,
    positions: [],
    //Bay Positions
    selectedPositions: [],
    isLoadingForm: false,
    //General
    bayQuantity: 0

  },
  getters: {
  },
  actions: {
    inactivar({ commit }, VesselBayJson ) {
      return Service.ejecutar('PUT', 'VesselBay-update', VesselBayJson, { root: 'VesselBayJson' })
      .then(
        response => {
          commit('defaultValues');
          return Promise.resolve(response.data.data[0]);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    updatePositions({ commit }, Json, ) {

      return Service.ejecutar('PUT', 'YardSlotStatus-update', Json, { root: 'YardJson' })
      .then(
        response => {
          commit('updatePosSuccess');
          return Promise.resolve(response.data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    changePositions(state, { item }){

      if(item.length>0)
          state.positions = item;

      else{
        state.positions.map(function(item){
          item.SlotJson.map(function(slotItem){
            slotItem?.YardSlotStatusId && delete slotItem.YardSlotStatusId
            slotItem?.YardSlotStatusName && delete slotItem.YardSlotStatusName
          })
        })
        state.positions = item;
        state.current = 0;
        state.collapseHeightPlan = false;
      }
    },
    deleteStatus(state) {
      state.positions.map(function(item){
        item.SlotJson.map(function(slotItem){
          slotItem?.YardSlotStatusId && delete slotItem.YardSlotStatusId
          slotItem?.YardSlotStatusName && delete slotItem.YardSlotStatusName
        })
      })
    },
    updatePosSuccess(state) {
      state.selectedPositions = [];
    },
    changePositionStatus(state, { original, nuevo }){
      let position = 0;
      let positionJson = 0;
      
      position = state.positions.findIndex((item) => item.StackCode === original.StackCode);
      positionJson = state.positions[position].SlotJson.findIndex((item) => item.SlotCode  === original.SlotCode);

      if(position != -1) {
        state.positions[position].SlotJson.splice(positionJson, 1, {
          ...original,
          YardSlotStatusId: nuevo.YardSlotStatusId,
          YardSlotStatusName: nuevo.YardSlotStatusName
        });
      }
    },
    //General
    defaultValues(state) {
      state.bay = null;
      state.current = 0;
      state.positions = [];
      state.selectedPositions = [];
      state.isLoadingForm = false;
    },
    setSteps(state, steps) {
      state.steps = steps;
    },
    nextStep(state) {
      if(state.current + 1 < state.steps)
        state.current++;
    },
    previousStep(state) {
      if(state.current - 1 >= 0)
        state.current--;
    }
  },
};