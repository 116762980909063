export const servicio = {
  namespaced: true,
  state: {
    servicioId: '',
    actList: ''
  },
  mutations: {
    set (state, value) {
      state.servicioId = value
    },
    listado (state, value) {
      state.actList = value
    }
  }
}
