import Service from '@/_services/service';

export const baygrid = {
  namespaced: true,
  state: {
    //Steps Form
    current: 0,
    steps: 4,
    isEdit: false,
    //Bay Data
    VesselId: '',
    bay: null,
    positionsToDeck: [],
    positionsUnderDeck: [],
    //Bay Positions
    selectedPositions: [],
    isLoadingForm: false,
    //General
    bayQuantity: 0
  },
  getters: {
    VesselBayPositionJson: state => {
      return state.selectedPositions.map((pos) => Object.assign({}, {
        VesselBayPosId: pos.VesselBayPosId,
        BayStatusId: pos.BayStatusId
      }));
    },
  },
  actions: {
    getBayById({ commit }, BayId) {
      return Service.ejecutar('GET', 'VesselBay-by-id', {
        VesselBayId: BayId
      })
      .then(
        response => {
          commit('hasContent', response.data.data[0]);
          return Promise.resolve(response.data.data[0]);
        },
        error => {
          commit('defaultValues');
          return Promise.reject(error);
        }
      );
    },
    generateBay({ commit, state }, VesselBayJson ) {
      let metodo = state.isEdit ? 'PUT':'POST';
      let ruta = state.isEdit ? 'VesselBay-update' : 'VesselBay-insert'; 

      return Service.ejecutar(metodo, ruta, VesselBayJson, { root: 'VesselBayJson' })
      .then(
        response => {
          commit('hasContent', response.data.data[0]);
          return Promise.resolve(response.data.data[0]);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    inactivar({ commit }, VesselBayJson ) {
      return Service.ejecutar('PUT', 'VesselBay-update', VesselBayJson, { root: 'VesselBayJson' })
      .then(
        response => {
          commit('defaultValues');
          return Promise.resolve(response.data.data[0]);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    updatePositions({ commit }, VesselBayPositionJson ) {
    
      return Service.ejecutar('PUT', 'VesselBayPosition-update', VesselBayPositionJson, { root: 'VesselBayPositionJson' })
      .then(
        response => {
          commit('updatePosSuccess');
          return Promise.resolve(response.data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    hasContent(state, bay) {
      state.VesselId = bay.VesselId;
      state.bay = bay;
      state.isEdit = true;
      state.positionsToDeck = bay.PositionsJson 
        ? bay.PositionsJson.filter((item) => item.BayLocName==='TO DECK')
        : bay.Positions.filter((item) => item.BayLocName==='TO DECK');
      state.positionsUnderDeck = bay.PositionsJson
        ? bay.PositionsJson.filter((item) => item.BayLocName==='UNDER DECK')
        : bay.Positions.filter((item) => item.BayLocName==='UNDER DECK');
    },
    //Setters
    setVesselId(state, VesselId) {
      state.VesselId = VesselId;
    },
    setSteps(state, steps) {
      state.steps = steps;
    },
    setBayQuantity(state, quantity) {
      state.bayQuantity = quantity;
    },
    setLoadingForm(state, payload){
      state.isLoadingForm = payload;
    },
    updatePosSuccess(state) {
      state.selectedPositions = [];
    },
    changePositionStatus(state, { original, nuevo }){
      let position = 0;
      if(original.BayLocId == "E40188C3-6F14-488F-90B4-4DD17E0E9A79"){
        position = state.positionsToDeck.findIndex((pos) => pos.VesselBayPosId === original.VesselBayPosId);

        if(position != -1) {
          state.positionsToDeck.splice(position, 1, {
            ...original,
            BayStatusId: nuevo.BayStatusId,
            BayStatusName: nuevo.BayStatusName
          });
        }
      } else if(original.BayLocId == "87BC945C-662F-44C8-B55D-20768D6D6C8D") {
        position = state.positionsUnderDeck.findIndex((pos) => pos.VesselBayPosId === original.VesselBayPosId);

        if(position != -1) {
          state.positionsUnderDeck.splice(position, 1, {
            ...original,
            BayStatusId: nuevo.BayStatusId,
            BayStatusName: nuevo.BayStatusName
          });
        }
      }

      let found = state.selectedPositions.findIndex((pos) => pos.VesselBayPosId === original.VesselBayPosId);
      
      if(found != -1) {
        state.selectedPositions.splice(found, 1, {
          VesselBayPosId: original.VesselBayPosId,
          BayStatusId: nuevo.BayStatusId
        });
      } else {
        state.selectedPositions.push({
          VesselBayPosId: original.VesselBayPosId,
          BayStatusId: nuevo.BayStatusId
        });
      }
    },
    //General
    defaultValues(state) {
      state.bay = null;
      state.isEdit = false;
      state.current = 0;
      state.positionsToDeck = [];
      state.positionsUnderDeck = [];
      state.selectedPositions = [];
      state.isLoadingForm = false;
    },
    //Form Controls
    resetCurrentStep(state) {
      state.current = 0;
    },
    nextStep(state) {
      if(state.current + 1 < state.steps)
        state.current++;
    },
    previousStep(state) {
      if(state.current - 1 >= 0)
        state.current--;
    }
  }
};